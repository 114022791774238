import React from 'react'
import { graphql } from 'gatsby'
import FlexiblePage from '../components/reusable/FlexiblePage'
import Seo from '../components/global/Seo'

export default function FlexiblePosts({data}) {
  return (
    <div className='sticky-wrapper'>
      <Seo seo={data.post.seo} />
      {data.post !== null ? <FlexiblePage post={true} title={data.post.title} data={data.post} /> : ''}
    </div>
  )
}

export const FlexiblePostsQuery = graphql`
 query FlexiblePostsQuery($id: String) {
    post: wpPost(id: {eq: $id}) {
      title 
      acfCustomThumbnail {
        customThumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo{
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
      acfFlexContent {
        contentBlocks {
          ... on WpPost_Acfflexcontent_ContentBlocks_TextBlock {
            fieldGroupName
            text
          }
          ... on WpPost_Acfflexcontent_ContentBlocks_ImageGallery {
            fieldGroupName
            imageGallery {
              caption
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPost_Acfflexcontent_ContentBlocks_Video {
            fieldGroupName
            videoEmbedCode
          }
          ... on WpPost_Acfflexcontent_ContentBlocks_Image2ImageColumns {
            fieldGroupName
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPost_Acfflexcontent_ContentBlocks_ImageFullWidth {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`